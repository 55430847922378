import { Head } from 'features/head'
import { Suspense, lazy } from 'react'

const CashbackWithdrawals = lazy(
  () => import('features/cashback-withdrawals/index')
)

export const CashbackWithdrawalsPage: React.FC = () => {
  return (
    <>
      <Head title='Cashback Withdrawals' />
      <Suspense fallback={<p>Loading...</p>}>
        <CashbackWithdrawals />
      </Suspense>
    </>
  )
}
